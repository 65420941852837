<template>
    <front-layout>
        <div class="page__top-line d-flex flex-column justify-center align-center fill-height pa-5 pb-16">
            <v-card flat min-width="300" max-width="500">
                <v-card-title>Password Reset</v-card-title>
                <v-card-text>
                    <v-form @submit="handleForgotPassword">
                        <v-row>
                            <v-col>
                                <v-text-field :error-messages="errors.email" label="Email" v-model="email"/>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions class="text-center">
                    <v-btn class="error" @click="handleForgotPassword">Reset</v-btn>
                </v-card-actions>
            </v-card>
        </div>
    </front-layout>
</template>

<script>
import {mapActions, mapMutations} from "vuex";
import FrontLayout from "../layouts/FrontLayout";
import Api from "../api/Api";

export default {
    name: "ForgotPassword",
    components: {FrontLayout},
    data: function () {
        return {
            email: '',
            errors: {}
        }
    },
    methods: {
        async handleForgotPassword(e) {
            e.preventDefault()
            const {email} = this;
            const response = await Api.forgotPassword(email).catch(e => this.errors = e.response.data.errors);

            if (response.status !== 200) {
                return
            }

            this.errors = {}
            this.showSnackBar({color: 'success', timeout: 3000, text: 'Password Reset Confirmed'})
        },
        ...mapActions(['signUp']),
        ...mapMutations(['showSnackBar']),
    },
}
</script>

<style scoped lang="scss">

</style>
